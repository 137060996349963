import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import {
    Button, TextField, Typography, Box, Backdrop, Stack, CircularProgress, Link
} from '@mui/material';
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
    ChangePasswordRequestModel, LoginStage, ApiViewEntityResponse,
    ApiViewResponseError, LoginStageResultModel, LoginResultModel
} from "../../interfaces"
import { logoutUser, changeUserPassword, spaSessionExpired, initError, spaResetErrorMessages } from "../../app/loginSlice"
import ErrorsBox from '../errorsBox';
import PasswordRequirements, {
    PasswordValidationResult, ValidatePassword, IsPasswordValid
} from '../passwordRequirements';
import { parseReturnUrl } from '../../functions';

export default function PasswordChange() {
    const [searchParams] = useSearchParams();
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [passwordValidity, setPasswordValidity] = useState<PasswordValidationResult>({
        lengthGt8: false,
        upperCaseChar: false,
        lowerCaseChar: false,
        digit: false,
        specialChar: false,
        matchConfirmPassword: false
    });
    const loginState = useAppSelector(store => store.login);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (loginState?.userData == null) {
            window.location.href = '/' + window.location.search;
        }
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!IsPasswordValid(password, passwordConfirmation)) {
            dispatch(initError("Please follow the requirements above."));
            return;
        }
        else {
            dispatch(spaResetErrorMessages());
        }

        const body: ChangePasswordRequestModel = {
            currentPassword: currentPassword,
            password: password,
            confirmPassword: passwordConfirmation,
            returnUrl: parseReturnUrl(searchParams.get("returnUrl"))
        };
        const dispatchResult = await dispatch(changeUserPassword(body));
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            const loginData = dispatchResult.payload as ApiViewEntityResponse<LoginStageResultModel<LoginResultModel>>;
            if (loginData.data.stage == LoginStage.Authenticated) {
                navigate({
                    pathname: '/client',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
            else if (loginData.data.stage == LoginStage.FillUserProfile) {
                navigate({
                    pathname: '/signin/profile',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
        }
        else if (dispatchResult.meta.requestStatus == "rejected") {
            const errors = dispatchResult.payload as ApiViewResponseError[];
            if (errors?.some(e => e.code == "UNAUTHORIZED")) {
                dispatch(spaSessionExpired());
                navigate({
                    pathname: '/',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
        }
    };

    const handleCancel = async () => {
        const dispatchResult = await dispatch(logoutUser());
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            navigate({
                pathname: '/',
                search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
            });
        }
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        const validationResult = ValidatePassword(e.target.value, passwordConfirmation);
        setPasswordValidity(validationResult);
    };

    const handlePasswordConfirmationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirmation(e.target.value);
        const validationResult = ValidatePassword(password, e.target.value);
        setPasswordValidity(validationResult);
    };

    return (
        <React.Fragment>
            <Box component="form" onSubmit={handleSubmit} width={1} noValidate>
                <Stack spacing={1.5}>
                    <Typography variant="h4" color="primary">Reset your account password</Typography>
                    <Box>
                        <Typography variant="subtitle1">Your password must:</Typography>
                        <PasswordRequirements valid={passwordValidity} />
                        <ErrorsBox errors={loginState.messages} />
                        <TextField
                            inputProps={{ style: { height: 12 }, autoComplete: 'new-password' }}
                            margin="normal" id="currentPassword" name="currentPassword" type="password"
                            label="Enter current password" autoComplete="current-password"
                            onChange={e => { setCurrentPassword(e.target.value) }}
                            required fullWidth autoFocus />
                        <TextField
                            sx={{ mt: 0.5 }} inputProps={{ style: { height: 12 }, autoComplete: 'new-password' }}
                            margin="normal" id="password" name="password" type="password"
                            label="Enter new password"
                            onChange={handlePasswordChange}
                            required fullWidth />
                        <TextField
                            sx={{ mt: 0.5 }} inputProps={{ style: { height: 12 }, autoComplete: 'new-password' }}
                            margin='dense' id="passwordConfirmation" name="passwordConfirmation" type="password"
                            label="Confirm new password"
                            onChange={handlePasswordConfirmationChange}
                            required fullWidth />
                    </Box>
                    <Button
                        id="passwordchange-continue-button"
                        type="submit" fullWidth variant="contained"
                    >
                        Continue
                    </Button>
                    <Typography variant="body1" pt={1}>
                        <Link id="passwordchange-cancel-link" href="#" onClick={handleCancel}>Cancel</Link>
                    </Typography>
                </Stack>
            </Box>
            <Backdrop open={loginState.loading} className="loading">
                <CircularProgress />
            </Backdrop>
        </React.Fragment>
    );
}
